/* Colors */
@font-face {
  font-family: Campton;
  src: url("/Themes/comeos/fonts/campton-light-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-light-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-light-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-light-webfont.woff") format("woff");
}
@font-face {
  font-family: Campton;
  font-weight: bold;
  src: url("/Themes/comeos/fonts/campton-bold-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-bold-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-bold-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-bold-webfont.woff") format("woff");
}
@font-face {
  font-family: Campton;
  font-weight: 600;
  src: url("/Themes/comeos/fonts/campton-semibold-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-semibold-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-semibold-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-semibold-webfont.woff") format("woff");
}
.altai-theme-content #bodyView .inlinebadge {
  display: inline-block;
  width: 20px;
  height: 20px;
  padding: 6px 6px;
  font-size: 11px;
  /* font-weight: bold; */
  color: #fff;
  line-height: 1;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
  background-color: #b1b1b1;
  border-radius: 50%;
  margin-top: 5px;
  margin-left: 20px;
}
.altai-theme-content #bodyView .inlineaggregations {
  display: flex;
  justify-content: center;
}
.altai-theme-content #bodyView .grid-item.who-is-who {
  margin-bottom: 25px;
  margin-right: 20px;
}
.altai-theme-content #bodyView .grid-item.who-is-who .altai-card.whoiswhocard {
  min-height: 250px;
  height: 400px;
  width: 265px;
  display: flex;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: center;
}
.altai-theme-content #bodyView .grid-item.who-is-who .altai-card.whoiswhocard.highcard {
  height: 470px;
}
.altai-theme-content #bodyView .grid-item.who-is-who .altai-card.whoiswhocard .contactdata {
  padding-top: 20px;
  font-style: initial;
  text-align: left;
}
.altai-theme-content #bodyView .grid-item.who-is-who .altai-card.whoiswhocard .altai-whoiswho-image {
  text-align: center;
  position: absolute;
}
.altai-theme-content #bodyView .grid-item.who-is-who .altai-card.whoiswhocard .altai-whoiswho-image .profile-picture.profile-picture-circle {
  width: 100%;
  overflow: hidden;
  border-radius: 0;
}
.altai-theme-content #bodyView .grid-item.who-is-who .altai-card.whoiswhocard .altai-card-image {
  width: 100%;
}
.altai-theme-content #bodyView .grid-item.who-is-who .altai-card.whoiswhocard .altai-card-title {
  /*text-align: center;*/
}
.altai-theme-content #bodyView .grid-item.who-is-who .altai-card.whoiswhocard .altai-card-title h5 {
  margin-bottom: 0.625em;
}
.altai-theme-content #bodyView .grid-item.who-is-who .altai-card.whoiswhocard .altai-card-function-title {
  font-style: italic;
  font-size: 0.8em;
  /*text-align: center;*/
  margin-bottom: 5px;
}
.altai-theme-content #bodyView .grid-item.who-is-who .altai-card.whoiswhocard .altai-card-contact-item {
  font-size: 0.8em;
  text-align: left;
  margin-bottom: 5px;
}
